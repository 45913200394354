	
const Prismic = require('prismic-javascript');
const jQuery = require('jquery');
const $ = window.$ = window.jQuery = jQuery;
const jQueryUIBundle = require('jquery-ui-bundle');
const slick = require('slick-carousel/slick/slick');

import './scss/index.scss';

$(() => {
  const $accordion = $('[data-accordion]');
  if (!$accordion.length) {
    return;
  }

  let apiEndpoint = "https://hether.prismic.io/api/v2";
  if (window.location.hostname === 'dev.hether.co' || window.location.hostname === 'hether-staging.netlify.app') {
    apiEndpoint = "https://hether-staging.prismic.io/api/v2";
  }

  Prismic.api(apiEndpoint).then((api) => {
    return api.query(
      [
        Prismic.Predicates.at('document.type', 'single_elements'),
      ], 
      { pageSize : 25, page : 1 }
    );
  }).then((response) => {
    if (response.results[0] !== undefined) {
      const single_data = response.results[0].data;

      $('[data-title]').text(single_data.site_title[0].text);
      $('[data-desc]').html(single_data.site_description[0].text);
      $('footer').html(single_data.site_footer[0].text);
    }
  });

  Prismic.api(apiEndpoint).then((api) => {
    return api.query(
      [
        Prismic.Predicates.at('document.type', 'project'),
        /*, Prismic.Predicates.at('document.tags', ['featured'])*/
      ], 
      { pageSize : 25, page : 1, orderings : '[my.project.position]' }
    );
  }).then((response) => {
    // console.log(response);
    // console.log("Projects: ", response.results);

    let projects_html = '';
    let i = 0;
    const max_images = 5;
    response.results.forEach((project) => {
      const project_data = project.data;
      // console.log(project_data);

      let url_html = '';
      if (project_data.url.url !== undefined && project_data.url !== null) {
        url_html = `<a href="${project_data.url.url}" target="${project_data.url.target}" class="Accordion__url">${project_data.url.url}</a>`;
      }

      let images = '';
      let total_images = 0;
      for (let i = 1; i <= max_images; i++) {
        if (typeof project_data[`image_${i}`].url !== 'undefined') {
          images += `<img class="Accordion__image" src="${project_data[`image_${i}`].url}" alt="${project_data[`image_${i}`].alt}" />`;
          total_images++;
        }
      }

      const project_status = (project_data.project_status === 'none') ? '' : `<div class="Accordion__status Accordion__status--${project_data.project_status}" data-status>${project_data.project_status}</div>`;

      projects_html += `
        <div class="Accordion__item" data-total-images="${total_images}">
          <h3 class="Accordion__${project_data.title[0].type}">${project_data.title[0].text}${project_status}</h3>
          <div class="Accordion__content" data-index="${i}">
            <div class="Accordion__left">
              <div class="Accordion__carousel" data-project-carousel>
                ${images}
              </div>

              <div class="Accordion__controls Accordion__controls--mobile">
                <div class="Accordion__arrow previous" data-accordion-control></div>
                <div class="Accordion__arrow next" data-accordion-control></div>
              </div>
            </div>
            <div class="Accordion__right">
              <p class="Accordion__description">${project_data.description[0].text}</p>
              ${url_html}

              <div class="Accordion__controls Accordion__controls--desktop">
                <div class="Accordion__arrow previous" data-accordion-control></div>
                <div class="Accordion__arrow next" data-accordion-control></div>
              </div>
            </div>
          </div>
        </div>
      `;

      i++;
    });

    $accordion.html(projects_html);

    // $accordion.accordion({
    $('.Accordion__item').accordion({ // technically 1 accordion per item because we don't want to automatically close it when opening a new item
      collapsible: true,
      active: false,
      heightStyle: 'content',
      beforeActivate: (e, ui) => {
        const item_carousel = ui.newHeader.parent().find('[data-project-carousel]');
        item_carousel.slick('refresh');
      },
    });

    $('[data-project-carousel]').slick({
      lazyLoad: 'progressive',
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: false,
      rows: 0, /* Removes the extra div added by Slick */
    });
    

    $(document).on('click', '[data-accordion-control]', (e) => {
      e.preventDefault();
      const $item = $(e.currentTarget);
      // accordionControl($item);
      carouselControl($item);
    });

    $(document).on('click', '.ui-accordion-header', (e) => {
      const $item = $(e.currentTarget);
      const $content = $item.parent().find('.Accordion__content');

      if ($content.hasClass('fade-in')) {
        $content.removeClass('fade-in').addClass('fade-out');
      } else {
        $content.removeClass('fade-out').addClass('fade-in');
      }
    });
    
    // ui-corner-top ui-state-default ui-accordion-icons ui-accordion-header-active ui-state-active

    $(window).resize(() => {
      // $accordion.accordion('refresh');
      $('.Accordion__item').accordion('refresh');
      $('[data-project-carousel]').slick('refresh');
    });
  });
});

const carouselControl = ($item) => {
  const $carousel_item = $item.closest('.Accordion__content').find('[data-project-carousel]');
  console.log($item, $item.closest('.Accordion__content').length)
  const goto = ($item.hasClass('next') ? 'slickNext' : 'slickPrev');
  $carousel_item.slick(goto);
}

const accordionControl = ($item) => {
  let goto = $item.closest('.Accordion__content').data('index');

  goto = ($item.hasClass('next') ? goto + 1 : goto - 1);

  if (goto === $('.Accordion__content').length) {
    goto = 0;
  }

  if (goto < 0) {
    goto = $('.Accordion__content').length - 1;
  }
  $accordion.accordion('option', 'active', goto);
}
